const initialState = {
  users: [],
  loading: true,
  error: null,
};

const handleData = (data) => {
  try {
    // Check if data is undefined or null
    if (!data) {
      return []; // Return an empty array if data is not provided
    }

    // Parse the first layer of JSON
    const parsedRole = data;

    // // Check if the result is still a string (for the nested JSON case)
    if (typeof parsedRole === "string") {
      return JSON.parse(parsedRole); // Parse the nested JSON string
    }
    console.log("parsedRole", data);

    return parsedRole; // Already an array
  } catch (error) {
    console.error("Error parsing data data:", error, "Input data:", data);
    return []; // Return an empty array on error
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        users: action.payload.map((user) => ({
          ...user,
          roles: handleData(user.roles), // Process role data for each user
          regions: handleData(user.regions), // Process region data for each user
        })),
        loading: false,
      };
    case "SET_USER_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "ADD_USER":
      return {
        ...state,
        users: [
          ...state.users,
          {
            ...action.payload,
            roles: handleData(action.payload.roles), // Process role data for each user
            regions: handleData(action.payload.regions), // Process region data for each user
          },
        ],
        loading: false,
      };
    case "UPDATE_USER":
      return {
        ...state,
        users: state.users.map((user) => {
          // Find a matching user in the payload
          const updatedUser = action.payload.find(
            (updated) => updated.email === user.email
          );
          return updatedUser
            ? {
                ...updatedUser,
                roles: handleData(updatedUser.roles), // Process role data for the updated user
                regions: handleData(updatedUser.regions), // Process region data for the updated user
              }
            : user; // If no match, keep the existing user unchanged
        }),
        loading: false,
      };

    case "DELETE_USER":
      return {
        ...state,
        users: state.users.filter(
          (user) => !action.payload.includes(user.email)
        ),
        loading: false,
      };
    case "SET_USER_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
