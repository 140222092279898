import React, { useState, useEffect } from "react";

import { Route, Routes, matchRoutes, useLocation } from "react-router-dom";

import styles from "./main.module.scss";

import { Header } from "../header";
import { ModalBrowserWidth } from "../../components/modalBrowserWidth/ModalBrowserWidth";
import { Notifications } from "../../pages/Notifications";
import { DashboardHome } from "../../pages/DashboardHome";
import { SelectAction } from "../../pages/selectaction/SelectAction";
import { QuickCalculations } from "../../pages/selectaction/QuickCalculations";
import { HotelKpis } from "../../pages/benchmarks/hotelKpis/HotelKpis";
import { HotelKpisHistory } from "../../pages/benchmarks/hotelKpis/HotelKpisHistory";
import { DevAndFin } from "../../pages/benchmarks/developAndFinance/DevAndFin";
import { DevAndFinHistory } from "../../pages/benchmarks/developAndFinance/DevAndFinHistory";
import { CityBenchmarksType } from "../../pages/benchmarks/cityBenchmarks/CityBenchmarksType";
import { CityBenchmarksHistory } from "../../pages/benchmarks/cityBenchmarks/CityBenchmarksHistory";
import { CityBenchmarks } from "../../pages/benchmarks/cityBenchmarks/CityBenchmarks";
import { ResortBenchmarksType } from "../../pages/benchmarks/resortBenchmarks/ResortBenchmarksType";
import { ResortBenchmarksHistory } from "../../pages/benchmarks/resortBenchmarks/ResortBenchmarksHistory";
import { ResortBenchmarks } from "../../pages/benchmarks/resortBenchmarks/ResortBenchmarks";
import { ServicedApartmentsType } from "../../pages/benchmarks/servicedApartments/ServicedApartmentsType";
import { ServicedApartmentsHistory } from "../../pages/benchmarks/servicedApartments/ServicedApartmentsHistory";
import { ServicedApartments } from "../../pages/benchmarks/servicedApartments/ServicedApartments";
import {
  VisitorNumHistory,
  AddGeneralAssumptions,
  VisitorNumAssumptions,
  GeneralAssumptions,
} from "../../pages/assumptions";
import {
  AssetsList,
  AssetsHistory,
  AssetVersionHistory,
} from "../../pages/assets";
import {
  MasterDataVersions,
  MasterDataHome,
  MasterDataCustom,
  MasterData,
} from "../../pages/masterdata";
import {
  DashboardOverview,
  DashboardRollout,
  DashboardPerformance,
  DashboardVisitors,
  DashboardTheLine,
  DashboardInvestment,
  DashboardAssetList,
  DashboardAssetListSummary,
} from "../../pages/dashboards";
import AdminPanel from "../../pages/admin/admin";
import UserDashboard from "../../pages/admin/dashboard";
import MasterFolders from "../../pages/marketreport/masterFolders";
import FolderAndFileGrid from "../../pages/marketreport/folderAndFile";
import AssetCard from "../../pages/assetCompare/assetCard";
import Review from "../../pages/assetCompare/review";
import Calculation from "../../pages/assetCompare/tables/mainView";
import Graphs from "../../pages/assetCompare/graphs/graph";
import AssetList from "../../pages/assetsList/list";
import AssetTable from "../../pages/assetsList/versions";
export const Main = ({ user }) => {
  //const navbarRef = useRef(null);

  /* const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 100) {
      navbarRef.current.classList.add(`${styles.navbar_scroll}`);
    } else {
      navbarRef.current.classList.remove(`${styles.navbar_scroll}`);
    }
  }; */

  const adminRoutes = () => {
    return (
      <>
        <Route path="/" element={<DashboardHome />} />

        <Route path="/Notifications" element={<Notifications />} />
        <Route
          path="/dashboard/overview/:dashboardLevel"
          element={<DashboardOverview />}
        />
        <Route
          path="/dashboard/performance/:dashboardLevel"
          element={<DashboardPerformance />}
        />
        <Route
          path="/dashboard/rollout/:dashboardLevel"
          element={<DashboardRollout />}
        />
        <Route
          path="/dashboard/visitors/:dashboardLevel"
          element={<DashboardVisitors />}
        />
        <Route path="/dashboard/ceo" element={<DashboardTheLine />} />
        <Route path="/dashboard/investment" element={<DashboardInvestment />} />
        <Route path="/MarketReports" element={<MasterFolders />} />
        <Route
          path="/MarketReports/:id/:name"
          element={<FolderAndFileGrid />}
        />
        <Route path="/AssetComp" element={<AssetCard />} />
        <Route path="/AssetComp/:type" element={<AssetCard />} />
        <Route path="/AssetComp/review" element={<Review />} />
        <Route path="/AssetComp/calculation" element={<Calculation />} />
        <Route path="AssetComp/graph" element={<Graphs />} />
        <Route path="/*" element={<DashboardHome />} />
      </>
    );
  };

  const renderRoutes = () => {
    let userRole = user.roles?.[0] || "";
    userRole = userRole.toLowerCase();

    switch (userRole) {
      case "admin":
        return (
          <>
            <Route path="/" element={<DashboardHome />} />
            <Route
              path="/GeneralAssumptions"
              element={<GeneralAssumptions />}
            />

            <Route
              path="/GeneralAssumptions/:id"
              element={<GeneralAssumptions />}
            />
            <Route
              path="/GeneralAssumptions/:id/:draftId"
              element={<GeneralAssumptions />}
            />
            <Route
              path="/AddGeneralAssumptions"
              element={<AddGeneralAssumptions />}
            />

            <Route
              path="/VisitorNumAssumptions"
              element={<VisitorNumAssumptions />}
            />
            <Route
              path="/VisitorNumAssumptions/:id"
              element={<VisitorNumAssumptions />}
            />
            <Route
              path="/VisitorNumAssumptions/:id/:draftId"
              element={<VisitorNumAssumptions />}
            />
            <Route path="/VisitorNumHistory" element={<VisitorNumHistory />} />

            <Route path="/AssetList" element={<AssetsList />} />
            <Route path="/AssetList/:type" element={<AssetsList />} />
            <Route
              path="/AssetList/:type/:projectNameValue"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id/:draftId"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id/:draftId/:clone"
              element={<AssetsList />}
            />
            <Route
              path="/AssetVersionHistory"
              element={<AssetVersionHistory />}
            />
            <Route
              path="/AssetVersionHistory/:id"
              element={<AssetVersionHistory />}
            />

            <Route path="/AssetsHistory" element={<AssetsHistory />} />
            <Route path="/HotelKpisHistory" element={<HotelKpisHistory />} />
            <Route path="/HotelKpis" element={<HotelKpis />} />
            <Route path="/HotelKpis/:id" element={<HotelKpis />} />
            <Route path="/HotelKpis/:id/:draftId" element={<HotelKpis />} />

            <Route path="/DevAndFinHistory" element={<DevAndFinHistory />} />
            <Route path="/DevAndFin" element={<DevAndFin />} />
            <Route path="/DevAndFin/:id" element={<DevAndFin />} />
            <Route path="/DevAndFin/:id/:draftId" element={<DevAndFin />} />
            <Route path="/Notifications" element={<Notifications />} />
            <Route path="/MasterData/:type" element={<MasterData />} />
            <Route path="/MasterData/:type/:id" element={<MasterData />} />
            <Route
              path="/MasterData/:type/:id/:draftId"
              element={<MasterData />}
            />

            <Route
              path="/MasterDataCustom/:type"
              element={<MasterDataCustom />}
            />
            <Route
              path="/MasterDataCustom/:type/:id"
              element={<MasterDataCustom />}
            />
            <Route
              path="/MasterDataCustom/:type/:id/:draftId"
              element={<MasterDataCustom />}
            />
            <Route path="/MasterDataCustom" element={<MasterDataCustom />} />
            <Route path="/MasterDataHome" element={<MasterDataHome />} />
            <Route path="/MarketReports" element={<MasterFolders />} />
            <Route
              path="/MarketReports/:id/:name"
              element={<FolderAndFileGrid />}
            />
            <Route
              path="/MasterDataVersions/:type"
              element={<MasterDataVersions />}
            />
            <Route
              path="/CityBenchmarksType"
              element={<CityBenchmarksType />}
            />
            <Route path="/CityBenchmarks" element={<CityBenchmarks />} />
            <Route path="/CityBenchmarks/:type" element={<CityBenchmarks />} />
            <Route
              path="/CityBenchmarks/:type/:id"
              element={<CityBenchmarks />}
            />
            <Route
              path="/CityBenchmarks/:type/:id/:draftId"
              element={<CityBenchmarks />}
            />
            <Route
              path="/CityBenchmarksHistory/:type"
              element={<CityBenchmarksHistory />}
            />
            <Route
              path="/ResortBenchmarksType"
              element={<ResortBenchmarksType />}
            />
            <Route path="/ResortBenchmarks" element={<ResortBenchmarks />} />
            <Route
              path="/ResortBenchmarks/:type"
              element={<ResortBenchmarks />}
            />

            <Route
              path="/ResortBenchmarks/:type/:id"
              element={<ResortBenchmarks />}
            />
            <Route
              path="/ResortBenchmarks/:type/:id/:draftId"
              element={<ResortBenchmarks />}
            />
            <Route
              path="/ResortBenchmarksHistory/:type"
              element={<ResortBenchmarksHistory />}
            />
            <Route
              path="/ServicedApartmentsType"
              element={<ServicedApartmentsType />}
            />
            <Route
              path="/ServicedApartments"
              element={<ServicedApartments />}
            />
            <Route
              path="/ServicedApartments/:type"
              element={<ServicedApartments />}
            />
            <Route
              path="/ServicedApartments/:type/:id"
              element={<ServicedApartments />}
            />
            <Route
              path="/ServicedApartments/:type/:id/:draftId"
              element={<ServicedApartments />}
            />
            <Route
              path="/ServicedApartmentsHistory/:type"
              element={<ServicedApartmentsHistory />}
            />
            <Route path="/SelectAction" element={<SelectAction />} />
            <Route
              path="/SelectAction/:projectNameValue"
              element={<SelectAction />}
            />
            <Route
              path="/SelectAction/:projectNameValue/:id"
              element={<SelectAction />}
            />
            <Route
              path="/SelectAction/:type/:projectNameValue/:id"
              element={<SelectAction />}
            />
            <Route path="/QuickCalculations" element={<QuickCalculations />} />
            <Route
              path="/QuickCalculations/:projectNameValue/:id"
              element={<QuickCalculations />}
            />
            <Route
              path="/dashboard/overview/:dashboardLevel"
              element={<DashboardOverview />}
            />
            <Route
              path="/dashboard/performance/:dashboardLevel"
              element={<DashboardPerformance />}
            />
            <Route
              path="/dashboard/rollout/:dashboardLevel"
              element={<DashboardRollout />}
            />
            <Route
              path="/dashboard/visitors/:dashboardLevel"
              element={<DashboardVisitors />}
            />
            <Route path="/dashboard/ceo" element={<DashboardTheLine />} />
            <Route
              path="/dashboard/investment"
              element={<DashboardInvestment />}
            />
            <Route path="/AssetComp" element={<AssetCard />} />
            <Route path="/AssetComp/:type" element={<AssetCard />} />
            <Route path="/AssetComp/review" element={<Review />} />
            <Route path="/AssetComp/calculation" element={<Calculation />} />
            <Route path="AssetComp/graph" element={<Graphs />} />
            <Route path="ListOfAssets" element={<AssetList />} />
            <Route
              path="dashboard/assetListDashboard"
              element={<DashboardAssetList />}
            />
            <Route
              path="dashboard/assetListSummaryDashboard"
              element={<DashboardAssetListSummary />}
            />
            <Route
              path="/AssetApprovalVersionHistory/:id/:name"
              element={<AssetTable />}
            />
          </>
        );
      case "proponent":
        return (
          <>
            <Route path="/" element={<DashboardHome />} />

            <Route
              path="/AssetList/:type/:projectNameValue"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id/:draftId"
              element={<AssetsList />}
            />

            <Route
              path="/AssetVersionHistory"
              element={<AssetVersionHistory />}
            />
            <Route
              path="/AssetVersionHistory/:id"
              element={<AssetVersionHistory />}
            />

            <Route path="/AssetsHistory" element={<AssetsHistory />} />

            <Route path="/Notifications" element={<Notifications />} />
            <Route
              path="/dashboard/overview/:dashboardLevel"
              element={<DashboardOverview />}
            />
            <Route
              path="/dashboard/performance/:dashboardLevel"
              element={<DashboardPerformance />}
            />
            <Route
              path="/dashboard/rollout/:dashboardLevel"
              element={<DashboardRollout />}
            />
            <Route
              path="/dashboard/visitors/:dashboardLevel"
              element={<DashboardVisitors />}
            />
            <Route path="/*" element={<DashboardHome />} />
            <Route path="/MarketReports" element={<MasterFolders />} />
            <Route
              path="/MarketReports/:id/:name"
              element={<FolderAndFileGrid />}
            />
            <Route path="/AssetComp" element={<AssetCard />} />
            <Route path="/AssetComp/:type" element={<AssetCard />} />
            <Route path="/AssetComp/review" element={<Review />} />
            <Route path="/AssetComp/calculation" element={<Calculation />} />
            <Route path="AssetComp/graph" element={<Graphs />} />
          </>
        );
      case "manager":
        return (
          <>
            <Route path="/AssetList" element={<AssetsList />} />
            <Route path="/AssetList/:type" element={<AssetsList />} />
            <Route
              path="/AssetList/:type/:projectNameValue"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id/:draftId"
              element={<AssetsList />}
            />
            <Route
              path="/AssetList/:type/:projectNameValue/:id/:draftId/:clone"
              element={<AssetsList />}
            />

            <Route
              path="/AssetVersionHistory"
              element={<AssetVersionHistory />}
            />
            <Route
              path="/AssetVersionHistory/:id"
              element={<AssetVersionHistory />}
            />

            <Route path="/AssetsHistory" element={<AssetsHistory />} />
            <Route path="/" element={<DashboardHome />} />

            <Route path="/Notifications" element={<Notifications />} />
            <Route
              path="/dashboard/overview/:dashboardLevel"
              element={<DashboardOverview />}
            />
            <Route
              path="/dashboard/performance/:dashboardLevel"
              element={<DashboardPerformance />}
            />
            <Route
              path="/dashboard/rollout/:dashboardLevel"
              element={<DashboardRollout />}
            />
            <Route
              path="/dashboard/visitors/:dashboardLevel"
              element={<DashboardVisitors />}
            />
            <Route path="/dashboard/ceo" element={<DashboardTheLine />} />
            <Route
              path="/dashboard/investment"
              element={<DashboardInvestment />}
            />

            <Route path="/*" element={<DashboardHome />} />
            <Route path="/MarketReports" element={<MasterFolders />} />
            <Route
              path="/MarketReports/:id/:name"
              element={<FolderAndFileGrid />}
            />
            <Route path="/AssetComp" element={<AssetCard />} />
            <Route path="/AssetComp/:type" element={<AssetCard />} />
            <Route path="/AssetComp/review" element={<Review />} />
            <Route path="/AssetComp/calculation" element={<Calculation />} />
            <Route path="AssetComp/graph" element={<Graphs />} />
          </>
        );
      case "head of department":
        return adminRoutes();
      case "ceo":
        return adminRoutes();
      case "investor":
        return (
          <>
            <Route path="/" element={<DashboardHome />} />
            <Route
              path="/dashboard/overview/:dashboardLevel"
              element={<DashboardOverview />}
            />
            <Route
              path="/dashboard/investment"
              element={<DashboardInvestment />}
            />
            <Route path="/AssetComp" element={<AssetCard />} />
            <Route path="/AssetComp/:type" element={<AssetCard />} />
            <Route path="/AssetComp/review" element={<Review />} />
            <Route path="/AssetComp/calculation" element={<Calculation />} />
            <Route path="AssetComp/graph" element={<Graphs />} />
            <Route path="/MarketReports" element={<MasterFolders />} />
            <Route
              path="/MarketReports/:id/:name"
              element={<FolderAndFileGrid />}
            />
          </>
        );

      case "visitor":
        return (
          <>
            <Route path="/" element={<DashboardHome />} />
            <Route
              path="/dashboard/overview/:dashboardLevel"
              element={<DashboardOverview />}
            />
            <Route
              path="/dashboard/visitors/:dashboardLevel"
              element={<DashboardVisitors />}
            />
            <Route
              path="/dashboard/performance/:dashboardLevel"
              element={<DashboardPerformance />}
            />
            <Route
              path="/dashboard/rollout/:dashboardLevel"
              element={<DashboardRollout />}
            />
            <Route path="/AssetComp" element={<AssetCard />} />
            <Route path="/AssetComp/:type" element={<AssetCard />} />
            <Route path="/AssetComp/review" element={<Review />} />
            <Route path="/AssetComp/calculation" element={<Calculation />} />
            <Route path="AssetComp/graph" element={<Graphs />} />
          </>
        );

      default:
        return <Route path="/" element={<DashboardHome />} />;
    }
  };

  const useCurrentPath = () => {
    const dashboardRoutes = [
      {
        path: "/dashboard/*",
      },
      {
        path: "/",
      },
      {
        path: "*",
      },
    ];
    const location = useLocation();
    const [{ route }] = matchRoutes(dashboardRoutes, location);

    return route.path;
  };

  const [windowDimensions, setWindowDimensions] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const updateSize = () => {
    setWindowDimensions({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [windowDimensions]);
  const currentRoute = useCurrentPath();
  //I changed the max width to be 1024 because we need to include the IPAD width as well
  const isWidthPopupVisible =
    !["/dashboard/*", "/"].includes(currentRoute) &&
    (windowDimensions.winWidth <= 1024 || windowDimensions.winHeight <= 500);
  return (
    <div
      id="main_content"
      className={styles.content} /* onScroll={handleScroll} */
    >
      <Header /* ref={navbarRef} */ />
      <div className={styles.container}>
        <Routes>
          {user.isSuperUser ? (
            <>
              <Route path="adminPanel" element={<AdminPanel user={user} />} />
              <Route path="adminPanel/dashboard" element={<UserDashboard />} />
            </>
          ) : (
            <></>
          )}
          {renderRoutes()}
        </Routes>
      </div>
      <div className={"width-popup"}>
        <ModalBrowserWidth isVisible={isWidthPopupVisible} />
      </div>
    </div>
  );
};
