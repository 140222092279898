import axiosClient from "../../api/index";
import { SET_REGION_DROPDOWN_ITEMS, SET_POSITION_DROPDOWN_ITEMS } from './actionTypes';
export const fetchRegionDropdownItems = () => async (dispatch) => {
    try {
        const response = await axiosClient.get('/v1/masterData/productionRegions');
        const regionDropdownItems = response.data.data[0].lookups;
        dispatch(setRegionDropdownItems(regionDropdownItems));
    } catch (error) {
        console.error('Error fetching region dropdown items:', error);
    }
};

export const setRegionDropdownItems = (regionDropdownItems) => ({
    type: SET_REGION_DROPDOWN_ITEMS,
    payload: regionDropdownItems
});

export const fetchPositionDropdownItems = () => async (dispatch) => {
    try {
        const response = await axiosClient.get('/v1/masterData/positioningProduction');
        const positionDropdownItems = response.data.data[0].lookups;
        dispatch(setPositionDropdownItems(positionDropdownItems));
    } catch (error) {
        console.error('Error fetching position dropdown items:', error);
    }
};

export const setPositionDropdownItems = (positionDropdownItems) => ({
    type: SET_POSITION_DROPDOWN_ITEMS,
    payload: positionDropdownItems
});

export const fetchRoles = () => async (dispatch) => {
    try {
        const response = await axiosClient.get('/v1/users/roles');
        const rolesData = response.data.roles;
        dispatch(setRoles(rolesData));
        // return Promise.resolve(); // Explicitly return a resolved promise

    } catch (error) {
        console.error('Error fetching roles:', error);
        // return Promise.reject(error); // Return a rejected promise

    }
}

export const setRoles = (roles) => ({
    type: 'SET_ROLES',
    payload: roles
});