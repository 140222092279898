import React, { useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainButton } from "../../components/button/MainButton";
import leftArrow3 from "../../assets/images/icons/leftArrow3.svg";

import { useDispatch, useSelector } from "react-redux";
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom";
import { fetchAllUsers } from "../../redux/actions/user";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  fetchRegionDropdownItems,
  fetchRoles,
} from "../../redux/actions/dropdown";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const UserDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const regionList = useSelector((state) => state.dropdown.region);
  const rolesList = useSelector((state) => state.dropdown.roles);
  const userList = useSelector((state) => state.users.users);

  useEffect(() => {
    dispatch(fetchRegionDropdownItems());
    dispatch(fetchRoles());
    dispatch(fetchAllUsers()).catch((error) =>
      console.error("Error fetching users:", error)
    );
  }, [dispatch]);

  // Calculate total number of users
  const totalUsers = userList.length;

  // Helper function to calculate counts for roles
  const getRoleCounts = () => {
    const counts = {};
    rolesList.forEach((role) => {
      const count = userList.filter((user) =>
        user.roles.includes(role.value)
      ).length;

      if (count > 0) {
        counts[role.label] = count;
      }
    });
    return counts;
  };

  const getRegionCounts = () => {
    const counts = {};
    regionList.forEach((region) => {
      const count = userList.filter((user) => {
        const hasAllRegions = user.regions.includes("all");
        return hasAllRegions || user.regions.includes(region.value);
      }).length;

      if (count > 0) {
        counts[region.label] = count;
      }
    });
    return counts;
  };

  const roleCounts = getRoleCounts();
  const regionCounts = getRegionCounts();

  const generateColors = (count) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(`hsl(${(i * 360) / count}, 70%, 50%)`);
    }
    return colors;
  };

  const regionData = {
    labels: Object.keys(regionCounts),
    datasets: [
      {
        label: "Region Distribution",
        data: Object.values(regionCounts),
        backgroundColor: generateColors(Object.keys(regionCounts).length),
        borderWidth: 1,
      },
    ],
  };

  // Function to generate distinct colors for roles
  const generateRoleColors = (count) => {
    const roleColors = [
      "#FF6384", // Red
      "#36A2EB", // Blue
      "#FFCE56", // Yellow
      "#4BC0C0", // Teal
      "#9966FF", // Purple
      "#FF9F40", // Orange
      "#B22222", // Fire Brick
      "#6B8E23", // Olive Drab
    ];
    const colors = [];
    for (let i = 0; i < count; i++) {
      // Cycle through the predefined role colors
      colors.push(roleColors[i % roleColors.length]);
    }
    return colors;
  };

  // Generate colors for roles and regions using distinct palettes
  const roleData = {
    labels: Object.keys(roleCounts),
    datasets: [
      {
        label: "Role Distribution",
        data: Object.values(roleCounts),
        backgroundColor: generateRoleColors(Object.keys(roleCounts).length), // Distinct colors for roles
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 10,
        },
      },
      datalabels: {
        color: "#FFFF",
        font: {
          size: 14,
          weight: "bold",
        },
        formatter: (value) => value,
        anchor: "end",
        align: "start",
      },
    },
  };

  return (
    <>
      <MainButton
        title="Back"
        btnStyle="transparent"
        fontColor="dark"
        marginRight="1"
        onPress={() => navigate("/adminPanel")}
        icon={leftArrow3}
      />

      <Box mt="24px" display="flex" justifyContent="space-between">
        <MainTitle title="User Statistics" />
      </Box>

      <MainBox mt="24px" height="auto" minHeight="100vh">
        <Box textAlign="center" mb="24px">
          <Text fontSize="2xl" fontWeight="bold">
            Total Users: {totalUsers}
          </Text>
        </Box>

        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-around"
          align="center"
          gap="40px"
          flexWrap="wrap"
        >
          {/* Role Distribution Chart */}
          <Box width="600px" height="600px">
            <Text textAlign="center" mb="16px" fontWeight="bold">
              Role Distribution
            </Text>
            <Pie data={roleData} options={chartOptions} />
          </Box>

          {/* Region Distribution Chart */}
          <Box width="600px" height="600px">
            <Text textAlign="center" mb="16px" fontWeight="bold">
              Region Distribution
            </Text>
            <Pie data={regionData} options={chartOptions} />
          </Box>
        </Flex>

        <Box mt="24px" display="flex" justifyContent="space-between"></Box>
      </MainBox>
    </>
  );
};

export default UserDashboard;
