import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Checkbox,
  IconButton,
  Divider,
  Tooltip,
  Input,
  Image,
  useToast,
  Switch,
  useDisclosure,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import plusIcon from "../../assets/images/icons/plus2.svg";
import remove from "../../assets/images/icons/removeMain.svg";
import deleteUsers from "../../assets/images/icons/deleteUsers.svg";
import updateUser from "../../assets/images/icons/updateUser.svg";
import powerBi from "../../assets/images/icons/powerBi.svg";
import { AddUserModal } from "./addUser";
import { fetchAllUsers, deleteUser } from "../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRegionDropdownItems,
  fetchRoles,
} from "../../redux/actions/dropdown";
import Select from "react-select";
import graph from "../../assets/images/icons/graph.svg";
import { useNavigate } from "react-router-dom";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";
import checked from "../../assets/images/icons/checked.svg";
import { ModalAlert } from "./confirmationModal";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import errIcon from "../../assets/images/icons/errsm.svg";
import { CloseIcon, SearchIcon, EditIcon } from "@chakra-ui/icons";

const AdminPanel = ({ user }) => {
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // For editing
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    roles: [],
    regions: [],
    isSuperUser: null, // null for no filter, true for super users, false for non-super users
  });
  let navigate = useNavigate();
  const toast = useToast();

  const closeToast = () => {
    toast.closeAll();
  };
  const dispatch = useDispatch();
  const regionList = useSelector((state) => state.dropdown.region);
  const rolesList = useSelector((state) => state.dropdown.roles);
  const userList = useSelector((state) => state.users.users);
  const isLoading = useSelector((state) => state.users.loading);

  const {
    isOpen: isDelUserModalOpen,
    onOpen: onDelUserModalOpen,
    onClose: onDelUserModalClose,
  } = useDisclosure();

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchRegionDropdownItems());
    dispatch(fetchRoles());
  }, [dispatch]);

  const handleFilterChange = (selectedOption, filterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: selectedOption || [],
    }));
  };

  const handleSearchChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: e.target.value.toLowerCase(),
    }));
  };

  const applyFilters = () => {
    // Start with a copy of the userList
    let filteredData = [...userList];

    // Apply search filter
    if (filters.search) {
      filteredData = filteredData.filter(
        (user) =>
          user.email.toLowerCase().includes(filters.search) ||
          user.displayName.toLowerCase().includes(filters.search)
      );
    }

    // Apply roles filter
    if (filters.roles.length > 0) {
      filteredData = filteredData.filter((user) =>
        filters.roles.some((role) => user.roles.includes(role.value))
      );
    }

    // Apply regions filter
    if (filters.regions.length > 0) {
      filteredData = filteredData.filter((user) =>
        filters.regions.some((region) => user.regions.includes(region.value))
      );
    }

    // Apply super user filter
    if (filters.isSuperUser !== null && filters.isSuperUser) {
      filteredData = filteredData.filter(
        (user) => user.isSuperUser === filters.isSuperUser
      );
    }

    return filteredData;
  };

  const setCheckedItems = (checkedUser, email) => {
    if (checkedUser) {
      setCheckedUsers([...checkedUsers, email]);
    } else {
      setCheckedUsers(checkedUsers.filter((user) => user !== email));
    }
  };

  const handleEditClick = (user) => {
    setCheckedUsers([]);
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (email) => {
    onDelUserModalClose();
    dispatch(deleteUser(checkedUsers))
      .then(() => {
        toast({
          position: "top",
          render: () => (
            <ToastAlert
              title={`User has been successfully deleted.`}
              icon={checked}
              closeToast={closeToast}
            />
          ),
        });
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        toast({
          position: "top",
          render: () => (
            <ToastAlert
              title={`Failed to delete user with email`}
              icon={errIcon}
              closeToast={closeToast}
              type="Error"
            />
          ),
        });
      });
  };

  const bulkUserUpdate = () => {
    return userList.filter((user) => {
      return checkedUsers.includes(user.email);
    });
  };

  const renderDropDownItems = (data) => {
    let extractedData = data.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    extractedData = extractedData.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    extractedData.unshift({ label: "All", value: "all" }); // Add "All" option

    // Combine "All" with the rest of the options
    return extractedData;
  };

  const handleSuperUserToggle = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      isSuperUser: e.target.checked,
    }));
  };

  const mapRegions = (regions) => {
    if (regions.includes("all")) {
      return ["All"];
    }
    return regionList
      .filter((region) => regions.includes(region.value))
      .map((region) => region.label);
  };

  const deleteSearch = () => {
    setFilters((prevFilters) => ({ ...prevFilters, search: "" }));
  };
  return (
    <Box>
      <Flex gap="20px" top="73px" width="100%" p={2}>
        <InputGroup size="md">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>

          <Input
            pr="4.5rem"
            type="text"
            placeholder="Search by Name or Email"
            value={filters.search}
            onChange={handleSearchChange}
          />
          <InputRightElement size="sm" width="4.5rem">
            {filters.search && (
              <CloseIcon
                boxSize={3}
                pointerEvents="auto"
                cursor="pointer"
                size="sm"
                onClick={deleteSearch}
                color="gray.300"
              />
            )}
          </InputRightElement>
        </InputGroup>

        <Select
          isMulti
          isClearable
          placeholder="Select Roles"
          options={rolesList}
          value={filters.roles}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "roles")
          }
          styles={{
            control: (provided) => ({ ...provided, width: 350, zIndex: 3 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
        />
        <Select
          isMulti
          isClearable
          placeholder="Select Regions"
          options={renderDropDownItems(regionList)}
          value={filters.regions}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "regions")
          }
          styles={{
            control: (provided) => ({ ...provided, width: 350, zIndex: 3 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
        />
        <Flex align="center" gap="8px">
          <Text whiteSpace="nowrap">Super User</Text>
          <Switch
            size="md"
            colorScheme="teal"
            isChecked={filters.isSuperUser === true}
            onChange={handleSuperUserToggle}
          />
        </Flex>
      </Flex>

      <Box mt="24px" display="flex" justifyContent="space-between">
        <MainTitle title="Admin Panel" />
        <Flex gap="24px">
          {checkedUsers.length > 0 && (
            <>
              <Tooltip label="Delete Selected Users">
                <IconButton
                  borderRadius="5px"
                  icon={<Image src={deleteUsers} />}
                  onClick={() => onDelUserModalOpen()}
                />
              </Tooltip>
              <Tooltip label="Update Selected Users">
                <IconButton
                  borderRadius="5px"
                  icon={<Image src={updateUser} />}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Tooltip>
            </>
          )}
          <Tooltip label="Dashboard">
            <IconButton
              borderRadius="5px"
              icon={<Image src={graph} />}
              onClick={() => {
                navigate("/adminPanel/dashboard");
              }}
            />
          </Tooltip>
          <Tooltip label="Power BI">
            <IconButton
              borderRadius="5px"
              icon={<Image src={powerBi} />}
              onClick={() => {
                window.open(process.env.REACT_APP_POWEBI_URL, "_blank");
              }}
            />
          </Tooltip>
          <Tooltip label="Add New User">
            <IconButton
              borderRadius="5px"
              icon={<Image src={plusIcon} />}
              onClick={() => {
                setCheckedUsers([]);
                setIsModalOpen(true);
              }}
            />
          </Tooltip>
        </Flex>
      </Box>

      {isLoading ? (
        <Box width="100%" paddingY={10}>
          <Center>
            <Spinner />
          </Center>
        </Box>
      ) : (
        <MainBox mt="24px">
          <Box w="100%" mx="auto" p="4" borderWidth="1px" borderRadius="md">
            {applyFilters().map((userInfo) => (
              <React.Fragment key={userInfo.email}>
                <Flex mt="7px" justify="space-between">
                  <Flex direction="column">
                    <Flex align="center">
                      <Checkbox
                        isChecked={checkedUsers.includes(userInfo.email)}
                        isDisabled={user.email == userInfo.email}
                        onChange={(e) =>
                          setCheckedItems(e.target.checked, userInfo.email)
                        }
                        size="md"
                        colorScheme="yellow"
                      />
                      <Text fontWeight="bold" ml={2}>
                        {userInfo.displayName} ({userInfo.email})
                      </Text>
                    </Flex>
                    <Text fontSize="sm" color="gray.500">
                      Role:{" "}
                      {userInfo.roles
                        .map(
                          (str) => str.charAt(0).toUpperCase() + str.slice(1)
                        )
                        .join(", ") || "-"}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      Regions: {mapRegions(userInfo.regions).join(", ") || "-"}
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      Super User: {userInfo.isSuperUser ? "Yes" : "No"}
                    </Text>
                  </Flex>
                  <Flex gap="2">
                    <IconButton
                      icon={<EditIcon />}
                      aria-label="Edit user"
                      onClick={() => handleEditClick(userInfo)}
                      isDisabled={user.email == userInfo.email}
                    />
                    <IconButton
                      icon={<Image src={remove} />}
                      aria-label="Delete user"
                      onClick={() => {
                        setCheckedItems(true, userInfo.email);
                        onDelUserModalOpen();
                      }}
                      isDisabled={user.email == userInfo.email}
                    />
                  </Flex>
                </Flex>
                <Divider mt="7px" />
              </React.Fragment>
            ))}
          </Box>
        </MainBox>
      )}
      {isModalOpen && (
        <AddUserModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedUser(null);
          }}
          bulkUpdate={checkedUsers.length > 0}
          userToEdit={selectedUser}
          userTobulkUpdate={bulkUserUpdate()}
        />
      )}

      <ModalAlert
        isOpen={isDelUserModalOpen}
        onClose={onDelUserModalClose}
        title={"Are you sure you want to delete this user?"}
        icon={infoCircle}
        onConfirmed={handleDeleteClick}
      />
    </Box>
  );
};

export default AdminPanel;
