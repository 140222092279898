import axiosClient from "../../api/index";

export const fetchAllUsers = () => async (dispatch) => {
  try {
    const response = await axiosClient.get("/v1/users");
    const users = response.data.users;
    dispatch(setUsers(users));
  } catch (error) {
    console.error("Error fetching users:", error);
  }
}

export const setUsers = (users) => ({
  type: "SET_USERS",
  payload: users,
});

export const addUser = (users, isUpdate) => async (dispatch) => {
console.log("isUpdate", isUpdate);
  try {
    const response = await axiosClient.post("/v1/users", { users });

    users[0].roles = JSON.stringify(users[0].roles);
    users[0].regions = JSON.stringify(users[0].regions);
    isUpdate ? dispatch(updateUserSuccess(users)) :
    dispatch(addUserSuccess(users[0]));
    // return Promise.resolve(); // Explicitly return a resolved promise
  } catch (error) {
    console.error("Error adding user:", error);
    // return Promise.reject(error); // Return a rejected promise
  }
}

export const addUserSuccess = (user) => ({
  type: "ADD_USER",
  payload: user,
});

export const updateUser = (user) => async (dispatch) => {
  console.log("user", user);
  try {
    const response = await axiosClient.put(`/v1/users/${user.email}`, user);
    const payload = response.data.user;
    dispatch(updateUserSuccess(payload));
  } catch (error) {
    console.error("Error updating user:", error);
  }
}


export const updateUserSuccess = (users) => ({
  type: "UPDATE_USER",
  payload: users,
});

export const deleteUser = (emails) => async (dispatch) => {
  try {
    const response = axiosClient.post(`v1/users/delete`, {emails: emails});
    // if (response.success) {
      dispatch(deleteUserSuccess(emails));
      return Promise.resolve(); // Explicitly return a resolved promise

    // } else {
    //   throw new Error("Failed to delete user");
    // }
  } catch (error) {
    console.error("Error deleting user:", error);
    return Promise.reject(error); // Return a rejected promise
  }
}

export const deleteUserSuccess = (email) => ({
  type: "DELETE_USER",
  payload: email,
});
