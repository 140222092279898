import React from "react";

import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import { MainButton } from "../../components/button/MainButton";

export const ModalAlert = ({
  isOpen,
  onClose,
  title,
  desc,
  icon,
  onConfirmed,

}) => {
  return (
      <Modal isOpen={isOpen}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.5)"
          backdropFilter="blur(7px)"
        />

        <ModalContent
          py={12}
          px={4}
          alignSelf="center"
          maxWidth="520px"
          className="modal_content"
        >
          <Center flexDirection="column">
            <img
              className="info_icon"
              src={icon}
              width="72px"
              height="72px"
              alt="checked icon"
            />
            <ModalHeader
              textAlign="center"
              color="#1D1F22"
              fontSize="20px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
              mt={2}
              className="modal_header"
            >
              {title}
            </ModalHeader>
          </Center>

          <ModalBody textAlign="center">
            <Center>
              <Text fontSize="15px">{desc}</Text>
            </Center>
          </ModalBody>

          <ModalFooter>
            {" "}
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <SimpleGrid
                columns={{ sm: 2, md: 4 }}
                spacingX="15px"
                width="90%"
              >
                <GridItem colSpan={2}>
                  <MainButton
                    title="Yes"
                    onPress={() => onConfirmed()}
                    btnHeight="45px"
                    btnWidthFull={true}
                    btnStyle={ "transparent"}
                    border={true}
                    borderColor={ "gray"}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <MainButton
                    title="No"
                    // btnStyle={ ""}
                    // borderColor={ ""}
                    border={false}
                    onPress={() => onClose()}
                    btnHeight="45px"
                    btnWidthFull={true}
                  />
                </GridItem>
              </SimpleGrid>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
  );
};
