// reducers.js
import { combineReducers } from 'redux';
import cardReducer from './cardReducer';
import dropdownReducer from './dropdownReducer';
import calculationsReducer from './calculationsReducer'
import approvalReducer from './approval';
import filterReducer from './filterReducer';
import userReducer from './userReducer';


const rootReducer = combineReducers({
  card: cardReducer,
  dropdown: dropdownReducer,
  calculation: calculationsReducer,
  approval: approvalReducer,
  filters:filterReducer,
  users:userReducer

  

  // anotherState: anotherReducer
});

export default rootReducer;
