import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const CustomTable = ({
  hotelInfo,
  selectedYears,
  selectedColumns,
  totals,
  totalOfTotals,
  totalKeys,
  yearType,
}) => {
  const borderStyle = "2px solid gray";
  const yearsPresent = selectedYears.length > 0;

  const isSpecialColumn = (colValue) => 
    ["gfa", "capex", "irr", "capexPerKey"].includes(colValue);

  const renderYearHeaders = (years, prefix, border) => 
    years.map((year, index) => (
      <Th key={`${prefix}-${index}`} borderRight={border}>
        {year.label}
      </Th>
    ));

  const renderColumnHeaders = () => (
    <Tr>
      <Th>Region</Th>
      <Th>Name</Th>
      <Th>Positioning</Th>
      <Th borderRight={borderStyle}>Number of Keys</Th>
      {yearsPresent &&
        selectedColumns.map((col, index) => (
          <React.Fragment key={`header-${index}`}>
            <Th borderRight={!isSpecialColumn(col.value) ? "" : borderStyle}>
              {col.label}
            </Th>
            {!isSpecialColumn(col.value) && (
              <Th borderRight={borderStyle} colSpan={selectedYears.length}></Th>
            )}
          </React.Fragment>
        ))}
    </Tr>
  );

  const renderYearRows = () => (
    <Tr>
      <Th colSpan={4} borderRight={borderStyle}></Th>
      {selectedColumns.map((col, index) => (
        <React.Fragment key={`year-row-${index}`}>
          {!isSpecialColumn(col.value) &&
            renderYearHeaders(selectedYears, `col-${index}`, null)}
          <Th borderRight={borderStyle}>Total</Th>
        </React.Fragment>
      ))}
    </Tr>
  );

  const renderHotelRows = () =>
    hotelInfo.map((hotel, rowIndex) => (
      <Tr key={`hotel-row-${rowIndex}`}>
        <Td>{hotel.region}</Td>
        <Td>{hotel.name.substring(0, 30)}</Td>
        <Td>{hotel.positioning}</Td>
        <Td borderRight={borderStyle}>
          {hotel.numberOfKeys?.toLocaleString("en") || "-"}
        </Td>
        {yearsPresent &&
          selectedColumns.map((col, colIndex) => (
            <React.Fragment key={`hotel-col-${colIndex}`}>
              {selectedYears.map((year, yearIndex) => (
                !isSpecialColumn(col.value) && (
                  <Td key={`hotel-year-${yearIndex}`}>
                    {hotel.newData
                      ? yearType === "long"
                        ? hotel.newData[col.value]?.[year.value]
                        : hotel.newData[col.value]?.[
                            hotel.year + Number(year.value)
                          ]
                      : "-"}
                  </Td>
                )
              ))}
              <Td borderRight={borderStyle}>
                {!isSpecialColumn(col.value)
                  ? hotel.yearsTotal?.[col.value]
                  : hotel[col.value] || "-"}
              </Td>
            </React.Fragment>
          ))}
      </Tr>
    ));

  const renderTotalsRow = () => (
    <Tr>
      <Td colSpan={3}>Total (excl. in-planning-Fin. Pending assets)</Td>
      <Td borderRight={borderStyle}>
        {totalKeys.totalKeysExcl?.toLocaleString("en") || "-"}
      </Td>
      {yearsPresent &&
        selectedColumns.map((col, colIndex) => (
          <React.Fragment key={`total-row-${colIndex}`}>
            {selectedYears.map(
              (year, yearIndex) =>
                !isSpecialColumn(col.value) && (
                  <Td key={`total-${yearIndex}`}>
                    {totals[year.value]?.[col.value] || "-"}
                  </Td>
                )
            )}
            <Td borderRight={borderStyle}>
              {totalOfTotals[col.value] || "-"}
            </Td>
          </React.Fragment>
        ))}
    </Tr>
  );

  const renderTotalKeysRow = () => (
    <Tr>
      <Td colSpan={3}>Total keys (incl. all assets)</Td>
      <Td borderRight={borderStyle}>
        {totalKeys.allTotalKeys?.toLocaleString("en") || "-"}
      </Td>
    </Tr>
  );

  return (
    <TableContainer>
      <Table variant="simple" size="md" border="1px solid #E2E8F0">
        <Thead>
          {renderColumnHeaders()}
          {selectedColumns.length > 0 && yearsPresent && renderYearRows()}
        </Thead>
        <Tbody fontSize="sm">
          {renderHotelRows()}
          {renderTotalsRow()}
          {renderTotalKeysRow()}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
