import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Stack,
  useToast,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRegionDropdownItems,
  fetchRoles,
} from "../../redux/actions/dropdown";
import { addUser } from "../../redux/actions/user";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";
import checked from "../../assets/images/icons/checked.svg";
import errIcon from "../../assets/images/icons/errsm.svg";

export const AddUserModal = ({
  isOpen,
  onClose,
  bulkUpdate,
  userToEdit,
  userTobulkUpdate,
}) => {
  const dispatch = useDispatch();
  const regionList = useSelector((state) => state.dropdown.region);
  const rolesList = useSelector((state) => state.dropdown.roles);
  const [user, setUser] = useState({
    email: "",
    newEmail: "",
    displayName: "",
    roles: [],
    regions: [],
    isSuperUser: false,
  });
  const toast = useToast();

  const closeToast = () => {
    toast.closeAll();
  };
  // Fetch roles and regions
  useEffect(() => {
    if (isOpen) {
      dispatch(fetchRoles())
        .then((data) => {})
        .catch((error) => {
          toast({
            title: "Error fetching roles",
            description:
              error.message || "Failed to load roles from the server.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      dispatch(fetchRegionDropdownItems());
    }
  }, [dispatch]);

  const getMatchingLabels = (regionValue) => {
    const matchingItem = regionList.find((item) => item.value === regionValue); // Find the matching item
    return matchingItem ? matchingItem.label : null; // Return the label or null if not found
  };

  // Pre-fill fields if editing
  useEffect(() => {
    if (userToEdit) {
      const preSelectedRegions = userToEdit.regions.includes("all")
        ? renderDropDownItems(regionList)
        : userToEdit.regions.map((region) => ({
            label: getMatchingLabels(region),
            value: region,
          }));
      setUser({
        email: userToEdit.email,
        newEmail: userToEdit.email,
        displayName: userToEdit.displayName,
        roles: userToEdit.roles.map((role) => ({
          label: role.charAt(0).toUpperCase() + role.slice(1),
          value: role,
        })),
        regions: preSelectedRegions,
        isSuperUser: userToEdit.isSuperUser,
      });
    } else {
      setUser({
        email: "",
        newEmail: "",
        displayName: "",
        roles: [],
        regions: [],
        isSuperUser: false,
      });
    }
  }, [userToEdit, regionList]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (selectedRoles) => {
    setUser((prev) => ({ ...prev, roles: [selectedRoles] }));
  };

  const handleRegionChange = (selectedRegions) => {
    setUser((prev) => ({
      ...prev,
      regions: selectedRegions || [],
    }));
  };

  const handleIsSuperUserChange = (e) => {
    setUser((prev) => ({ ...prev, isSuperUser: e.target.checked }));
  };

  const handleSubmit = () => {
    let payload = {};
    if (bulkUpdate) {
      if (!userTobulkUpdate.length) {
        toast({
          title: "Validation Error",
          description: "No users selected for bulk update.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (user.regions.length == 0 || user.roles.length == 0) {
        toast({
          title: "Validation Error",
          description: "All fields are required.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      payload = userTobulkUpdate.map((userData) => ({
        email: userData.email,
        newEmail: userData.newEmail || userData.email,
        displayName: userData.displayName,
        roles: [user.roles[0].value],
        regions: user.regions.map((region) => region.value),
        isSuperUser: userData.isSuperUser,
      }));
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@neom\.com$/;
      payload = {
        email: user.email,
        newEmail: user.newEmail || user.email,
        displayName: user.displayName,
        roles: [user.roles[0].value],
        regions: user.regions.map((region) => region.value),
        isSuperUser: user.isSuperUser,
      };

      payload.regions =
        payload.regions.length == renderDropDownItems(regionList).length
          ? ["all"]
          : payload.regions;
      if (
        !payload.displayName ||
        !payload.email ||
        !payload.roles.length ||
        !payload.regions.length
      ) {
        toast({
          title: "Validation Error",
          description: "All fields are required.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (
        !emailRegex.test(payload.email) ||
        !emailRegex.test(payload.newEmail)
      ) {
        toast({
          title: "Validation Error",
          description: "Email must be in the format `example@neom.com`.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      payload = new Array(payload);
    }
    let text = userToEdit || bulkUpdate ? "edited" : "added";

    dispatch(addUser(payload, userToEdit || bulkUpdate))
      .then(() => {
        toast({
          position: "top",
          render: () => (
            <ToastAlert
              title={`User has been ${text} successfully`}
              icon={checked}
              closeToast={closeToast}
            />
          ),
        });
        onClose();
      })
      .catch((error) => {
        console.error("Error adding user:", error);
        toast({
          position: "top",
          render: () => (
            <ToastAlert
              title={`Failed to ${text} user with email`}
              icon={errIcon}
              closeToast={closeToast}
              type="Error"
            />
          ),
        });
      });

     onClose();
  };

  const renderDropDownItems = (data) => { 
    // Map the data to create the dropdown items
    let extractedData = data.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    extractedData = extractedData.sort((a, b) => a.label.localeCompare(b.label));
    extractedData.unshift({ label: "All", value: "all" }); // Add "All" option
  
  
    // Combine "All" with the rest of the options
    return extractedData
  };
  
  return (
    <Modal isOpen={isOpen} size="md" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {userToEdit || bulkUpdate ? "Edit User" : "Add New User"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            {!bulkUpdate ? (
              <>
                <Input
                  placeholder="Email"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                  //   isDisabled={!!userToEdit} // Disable email editing when editing
                />
                <Input
                  placeholder="Name"
                  name="displayName"
                  value={user.displayName}
                  onChange={handleInputChange}
                />{" "}
              </>
            ) : null}
            <Select
              //   isMulti
              placeholder="Select Role"
              options={rolesList}
              value={user.roles}
              onChange={handleRoleChange}
            />
            <Select
              isMulti
              placeholder="Select Regions"
              options={renderDropDownItems(regionList)}
              value={user.regions}
              onChange={handleRegionChange}
            />

            {!bulkUpdate ? (
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="is-super-user" mb="0">
                  Super User
                </FormLabel>
                <Switch
                  id="is-super-user"
                  isChecked={user.isSuperUser}
                  onChange={handleIsSuperUserChange}
                />
              </FormControl>
            ) : (
              <></>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>
            {userToEdit || bulkUpdate ? "Update User" : "Add User"}
          </Button>
          <Button variant="ghost" ml={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
