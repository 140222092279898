import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import * as XLSX from "xlsx";
import {
  Box,
  Flex,
  Spinner,
  Image,
  IconButton,
  Tooltip,
  Center,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { irr } from "node-irr";

import CustomTable from "./customTable";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import leftArrow3 from "../../assets/images/icons/leftArrow3.svg";
import downloadIcon from "../../assets/images/icons/download.svg";
import { getCalculatedOverview } from "../../api/AssetListAPI";
import AggregationKPI from "./aggregationKPI";

export const KpiTemplate = ({ back }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    region: [],
    position: [],
    brand: [],
    name: [],
    operator: []
  });

  const [selectedColumns, setSelectedColumns] = useState([
    { label: "ADR (SAR)", value: "adrSAR" },
    { label: "ADR (USD)", value: "adrUSD" },
    { label: "RevPAR (SAR)", value: "revPAR" },
    { label: "Occupancy Rate", value: "occupancyRate" },
  ]);
  const [selectedYears, setSelectedYears] = useState([
    { label: "2030", value: "2030" },
  ]);
  const [totals, setTotals] = useState({}); // State to store totals
  const [totalOfTotals, setTotalOfTotals] = useState({}); // State to store totals
  const regionList = useSelector((state) => state.approval.region);
  const positionList = useSelector((state) => state.approval.position);
  const brandList = useSelector((state) => state.approval.brand);
  const operatorList = useSelector((state) => state.approval.operator);

  const [nameList, setNameList] = useState([]);
  const [totalKeys, setTotalKeys] = useState({});
  const [yearType, setYearType] = useState("long");
  const [viewtype, setViewType] = useState("individual");
  const [calculationDone, setCalculationDone] = useState(false);

  const formatNumber = (num) =>
    num === null || num === undefined || num === "-"
      ? "-"
      : Math.round(num).toLocaleString();

  const formatPercentage = (num) =>
    num === null || num === undefined || num === "-"
      ? "-"
      : `${Math.round(num * 100)}%`;

  const yearOptions = Array.from({ length: 27 }, (v, i) => ({
    label: `${i + 2024}`,
    value: `${i + 2024}`,
  }));

  const yearOptionsShort = Array.from({ length: 27 }, (v, i) => ({
    label: `Year ${i + 1}`,
    value: `${i}`,
  }));

  const keyColumns = [
    { name: "ADR (SAR)", key: "adrSAR", format: formatNumber },
    { name: "ADR (USD)", key: "adrUSD", format: formatNumber },
    { name: "RevPAR (SAR)", key: "revPAR", format: formatNumber },
    {
      name: "Rooms Available",
      key: "roomsAvailableCombined",
      format: formatNumber,
    },
    { name: "Occupancy Rate", key: "occupancyRate", format: formatPercentage },
    {
      name: "Occupied Rooms (SAR)",
      key: "occupiedRooms",
      format: formatNumber,
    },
    {
      name: "Food and Beverage POR (SAR)",
      key: "foodAndBeveragePOR",
      format: formatNumber,
    },
    {
      name: "Other Operating Departments POR (SAR)",
      key: "otherOperatingDepartmentsPOR",
      format: formatNumber,
    },
    {
      name: "Total Revenue (SAR)",
      key: "totalOperatingRevenue",
      format: formatNumber,
    },
    {
      name: "Rooms Revenue (SAR)",
      key: "roomsRevenueCombined",
      format: formatNumber,
      revenues: true,
    },
    {
      name: "Food and Beverage Revenue (SAR)",
      key: "foodAndBeverageRevenueCombined",
      format: formatNumber,
      revenues: true,
    },
    {
      name: "Other Operating Departments Revenue (SAR)",
      key: "otherOperatingDepartmentsRevenueCombined",
      format: formatNumber,
      revenues: true,
    },
    { name: "Rooms Revenue (%)", key: "roomsRevenue", format: formatPercentage },
    {
      name: "Food and Beverage Revenue (%)",
      key: "foodAndBeverageRevenue",
      format: formatPercentage,
    },
    {
      name: "Other Operating Departments Revenue (%)",
      key: "otherOperatingDepartmentsRevenue",
      format: formatPercentage,
    },

    {
      name: "Gross Operating Profit (SAR) ",
      key: "grossOperatingProfitCombined",
      format: formatNumber,
    },

    {
      name: "Gross Operating Profit",
      key: "grossOperatingProfit",
      format: formatPercentage,
    },
    { name: "EBITDA (SAR)", key: "ebitda", format: formatNumber },
    { name: "EBITDA %", key: "ebitdaPercent", format: formatPercentage },
    { name: "EBIT (SAR)", key: "ebit", format: formatNumber },
    { name: "EBIT %", key: "ebitPercent", format: formatPercentage },
    { name: "Gross Floor Area (SQM)", key: "gfa", format: formatNumber },
    { name: "Total Capex (SAR)", key: "capex", format: formatNumber },
    { name: "Capex/Key (SAR)", key: "capexPerKey", format: formatNumber },
    { name: "IRR", key: "irr", format: formatPercentage },
  ];

  const columnOptions = keyColumns.map((col) => ({
    label: col.name,
    value: col.key,
  }));

  useEffect(() => {
    getCalculatedOverview().then((response) => {
      setData(response);
      setLoading(false);
      findFirstYear(response);
      setName(response);
    });
  }, []);

  const setName = (data) => {
    let listOfName = data.map((asset) => ({
      label: asset.name,
      value: asset.projectNameValue,
    }));

    setNameList(listOfName);
  };

  useEffect(() => {
    if (data.length) {
      const filteredData = applyFilters(data, filters);
      if(!filteredData.length) return;
      setFilteredData(filteredData);
      calculateTotalsForSelectedYears(filteredData, selectedYears);
      allAssetTotal(filteredData, selectedYears);
      calculateTotalOfTotal(filteredData);

      setCalculationDone(true);
    }
  }, [selectedColumns, selectedYears, data, filters]);

  const applyFilters = (data, filters) => {
    return data.filter(
      (asset) =>
        (!filters.name.length ||
          filters.name.some((name) => name.value === asset.projectNameValue)) &&
        (!filters.region.length ||
          filters.region.some(
            (region) => region.value === asset.regionLookup
          )) &&
        (!filters.position.length ||
          filters.position.some(
            (position) => position.value === asset.positioningLookup
          )) &&
        (!filters.brand.length ||
          filters.brand.some((brand) => brand.value === asset.brandLookup)) &&
        (!filters.operator.length ||
          filters.operator.some(
            (operator1) => operator1.value === asset.operatorLookup
          ))
    );
  };
  const findFirstYear = (data) => {
    data.forEach((asset) => {
      let unleveredCashflow = [];

      const { calculatedValues, projectNameValue } = asset;
      if (!calculatedValues) return;

      const firstOperationalYearValueIndex = calculatedValues.findIndex(
        (calc) => !calc.excludeFromOperationalYears
      );
      if (firstOperationalYearValueIndex === -1) return;

      const totalCapex =
        calculatedValues[firstOperationalYearValueIndex].totalDevelopmentCost;

      asset.capex = formatNumber(totalCapex);
      asset.capexPerKey =
        asset.numberOfKeys != 0
          ? formatNumber(totalCapex / asset.numberOfKeys)
          : "0";
      asset.irr = formatPercentage(calculatedValues[0].unleveredIRR);
      asset.gfa = formatNumber(asset.gfa);
      const yearArr = getYearFromFirstIndexToEnd(
        calculatedValues,
        firstOperationalYearValueIndex
      );
      let output = {};
      let yearRange = Array.from(
        { length: 2050 - 2024 + 1 },
        (_, i) => 2024 + i
      );
      // Loop through keyColumns to extract relevant data
      unleveredCashflow = [];
      // Initialize an empty object for each column key
      output = {
        adrSAR: {},
        adrUSD: {},
        roomsRevenueCombined: {},
        occupiedRooms: {},
        totalOperatingRevenue: {},
        occupancyRate: {},
        roomsAvailableCombined: {},
        revPAR: {},
        foodAndBeverageRevenueCombined: {},
        otherOperatingDepartmentsRevenueCombined: {},
        foodAndBeveragePOR: {},
        otherOperatingDepartmentsPOR: {},
        roomsRevenue: {},
        foodAndBeverageRevenue: {},
        otherOperatingDepartmentsRevenue: {},
        grossOperatingProfitCombined: {},
        grossOperatingProfit: {},
        ebitda: {},
        ebitdaPercent: {},
        ebit: {},
        ebitPercent: {},
      };

      // Loop through the year range
      yearRange.forEach((year) => {
        // Find the data for the current year
        let yearData = yearArr.find((d) => d.year === year);

        // If the year exists and the key has a value, otherwise set to '-'
        if (yearData) {
          output.adrSAR[year] = formatNumber(yearData.kpisOverview.adrSAR);
          output.adrUSD[year] = formatNumber(yearData.kpisOverview.adrUSD);
          output.roomsRevenueCombined[year] = formatNumber(
            yearData.revenues.roomsRevenueCombined
          );
          output.occupiedRooms[year] = formatNumber(
            yearData.kpisOverview.occupiedRooms
          );
          output.totalOperatingRevenue[year] = formatNumber(
            yearData.totalOperatingRevenue
          );
          output.occupancyRate[year] = formatPercentage(
            yearData.kpisOverview.occupancyRate
          );
          output.roomsAvailableCombined[year] = formatNumber(
            yearData.roomsAvailableCombined
          );
          output.revPAR[year] = formatNumber(yearData.kpisOverview.revPAR);

          output.foodAndBeverageRevenueCombined[year] = formatNumber(
            yearData.revenues.foodAndBeverageRevenueCombined
          );

          output.otherOperatingDepartmentsRevenueCombined[year] = formatNumber(
            yearData.revenues.otherOperatingDepartmentsRevenueCombined
          );

          output.foodAndBeveragePOR[year] = formatNumber(
            yearData.kpisOverview.foodAndBeveragePOR
          );

          output.otherOperatingDepartmentsPOR[year] = formatNumber(
            yearData.kpisOverview.otherOperatingDepartmentsPOR
          );

          output.roomsRevenue[year] = formatPercentage(
            yearData.kpisOverview.roomsRevenue
          );

          output.foodAndBeverageRevenue[year] = formatPercentage(
            yearData.kpisOverview.foodAndBeverageRevenue
          );

          output.otherOperatingDepartmentsRevenue[year] = formatPercentage(
            yearData.kpisOverview.otherOperatingDepartmentsRevenue
          );

          output.grossOperatingProfitCombined[year] = formatNumber(
            yearData.grossOperatingProfitCombined
          );

          output.grossOperatingProfit[year] = formatPercentage(
            yearData.kpisOverview.grossOperatingProfit
          );

          output.ebitda[year] = formatNumber(yearData.ebitda);
          output.ebitdaPercent[year] = formatPercentage(yearData.ebitdaPercent);

          output.ebit[year] = formatNumber(yearData.ebit);
          output.ebitPercent[year] = formatPercentage(yearData.ebitPercent);
          unleveredCashflow.push(yearData.unleveredCashflow);
        } else {
          // If data for the year doesn't exist, set it to '-'
          output.adrSAR[year] = "-";
          output.adrUSD[year] = "-";
          output.roomsRevenueCombined[year] = "-";
          output.occupiedRooms[year] = "-";
          output.totalOperatingRevenue[year] = "-";
          output.occupancyRate[year] = "-";
          output.roomsAvailableCombined[year] = "-";
          output.revPAR[year] = "-";
          output.foodAndBeverageRevenueCombined[year] = "-";
          output.otherOperatingDepartmentsRevenueCombined[year] = "-";
          output.foodAndBeveragePOR[year] = "-";
          output.otherOperatingDepartmentsPOR[year] = "-";
          output.roomsRevenue[year] = "-";
          output.foodAndBeverageRevenue[year] = "-";
          output.otherOperatingDepartmentsRevenue[year] = "-";
          output.grossOperatingProfitCombined[year] = "-";
          output.grossOperatingProfit[year] = "-";
          output.ebitda[year] = "-";
          output.ebitdaPercent[year] = "-";
          output.ebit[year] = "-";
          output.ebitPercent[year] = "-";
        }
      });

      asset.year = yearArr[0].year;
      asset.newData = output;
      asset.unleveredCashflow = unleveredCashflow;
    });
  };

  const renderDropDownItems = (data) => {
    return data
      .reduce((unique, item) => {
        if (!unique.some((elem) => elem.label === item.label)) {
          unique.push({ label: item.label, value: item.value });
        }
        return unique;
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const getYearFromFirstIndexToEnd = (array1, startIndex) => {
    if (startIndex < 0 || startIndex >= array1.length) {
      console.error("Invalid startIndex");
      return [];
    }
    return array1.slice(startIndex);
  };

  const handleFilterChange = (selectedOption, filterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: selectedOption || [],
    }));
  };

  const keyColumnsMap = keyColumns.reduce((acc, col, index) => {
    acc[col.key] = index;
    return acc;
  }, {});

  const exportToExcel = () => {
    if (
      !filteredData.length ||
      !selectedColumns.length ||
      !selectedYears.length
    )
      return;

    // Define the base headers (static)
    const baseHeaders = ["Region", "Name", "Positioning", "Number of Keys"];

    // Create a multi-level header for the metrics per year
    const firstRowHeaders = [...baseHeaders];
    const secondRowHeaders = [...baseHeaders.map(() => "")]; // Blank cells for merged headers in the first row

    selectedColumns.forEach((col) => {
      selectedYears.forEach((year) => {
        firstRowHeaders.push(col.label); // Metric name (e.g., ADR, RevPAR)
        secondRowHeaders.push(year.label); // Year under the metric (e.g., 2030, 2030)
      });
      secondRowHeaders.push("Total"); // Add 'Total' after each metric
    });

    // Map the filtered data and add dynamic columns for each year and selected column
    const dataToExport = filteredData.map((item) => {
      // Base data (static columns)
      const rowData = [
        item.regionLookup,
        item.projectNameValue,
        item.positioningLookup,
        item.numberOfKeys,
      ];

      // Dynamic data (based on selected columns and years)
      const dynamicData = [];
      selectedColumns.forEach((col) => {
        selectedYears.forEach((year) => {
          const columnKey = col.value;
          dynamicData.push(item.newData?.[columnKey]?.[year.value] || "-"); // Add data for each year and column
        });
        dynamicData.push(item.yearsTotal?.[col.value] || "-"); // Add total for the selected column
      });

      return [...rowData, ...dynamicData];
    });

    const totalsRow = ["Total", "", "", ""]; // Static columns for the totals row
    selectedColumns.forEach((col) => {
      selectedYears.forEach((year) => {
        totalsRow.push(totals[year.value][col.value]);

        // filteredData.forEach((item, data) => {
        //   console.log(item.yearsTotal?.[col.value]);
        //   totalsRow.push(item.yearsTotal?.[col.value]|| "-");
        // });
      });
      const totalOverall = totalOfTotals[col.value];
      totalsRow.push(totalOverall || "-"); // Push the total for the column
    });

    // Append the totals row to the data
    dataToExport.push(totalsRow);

    // Create worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet([
      firstRowHeaders,
      secondRowHeaders,
      ...dataToExport,
    ]);

    // Merge the first row headers for each metric and its years + total
    const mergeRanges = [];
    let startCol = baseHeaders.length;
    selectedColumns.forEach(() => {
      mergeRanges.push({
        s: { r: 0, c: startCol },
        e: { r: 0, c: startCol + selectedYears.length }, // Merging across years and the 'Total' column
      });
      startCol += selectedYears.length + 1; // Move past the total column as well
    });

    worksheet["!merges"] = mergeRanges; // Apply the merges

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "KPI Data");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, `KPI_Assets_${new Date().getTime()}.xlsx`);
  };

  const exportAggregationToExcel = () => {
    if (
      !filteredData.length ||
      !selectedColumns.length ||
      !selectedYears.length
    )
      return;
    const baseHeaders = ["KPI"];
    const firstRowHeaders = [...baseHeaders];
    selectedYears.forEach((year) => {
      firstRowHeaders.push(year.label); // Year under the metric (e.g., 2030, 2030)
    });
    firstRowHeaders.push("Total"); // Add 'Total' after each metric
    const dataToExport = selectedColumns.map((col) => {
      // Base data (static columns)

      // Dynamic data (based on selected columns and years)
      const dynamicData = [];
      dynamicData.push(col.label); // Add data for each year and column

      selectedYears.forEach((year) => {
        dynamicData.push(totals[year.value][col.value]);
        // Add data for each year and column
      });
      dynamicData.push(totalOfTotals[col.value] || "-"); // Add total for the selected column

      return [...dynamicData];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([
      firstRowHeaders,
      ...dataToExport,
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "KPI Data");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, `Aggregation_KPI_Assets_${new Date().getTime()}.xlsx`);
  };

  // Horizntal Total for asset
  const setIRRTotal = (calculatedValues) => {
    let irrArr = Array(50).fill(0);

    calculatedValues.forEach((item, index) => {
      let position = (irrArr[item.year - 2001] = item.unleveredCashflow || 0);
    });
    // console.log("irrArr", irrArr);
    return irrArr;
  };

  const calculteTotalIRR = (allIrrArray) => {
    // Initialize an empty array to store the sums
    let summedArray = new Array(allIrrArray[0].length).fill(0);

    // Sum each value at the same index across all arrays
    for (let i = 0; i < allIrrArray.length; i++) {
      for (let j = 0; j < allIrrArray[i].length; j++) {
        summedArray[j] += allIrrArray[i][j];
      }
    }

    // Function to remove leading zeros

    // Get the final result without leading zeros
    let finalResult = removeLeadingZeros(summedArray);

    let finalIrrTotal = irr(finalResult);
    return finalIrrTotal;
  };
  const removeLeadingZeros = (array) => {
    let firstNonZeroIndex = array.findIndex((value) => value !== 0);
    return array.slice(firstNonZeroIndex);
  };
  const calculateTotalOfTotal = (data) => {
    let totals = {
      totalAdrSar: 0,
      totalAdrUsd: 0,
      totalRoomsRevenueCombined: 0,
      totalOccupiedRooms: 0,
      totalTotalRevenueCombined: 0,
      totalOccupancyRate: 0,
      totalRoomAvailable: 0,
      totalRevParSar: 0,
      totalFoodAndBeverageRevenueCombined: 0,
      totalOtherOperatingDepartmentsRevenueCombined: 0,
      totalFoodAndBeveragePOR: 0,
      totalOtherOperatingDepartmentsPOR: 0,
      totalRoomsRevenue: 0,
      totalFoodAndBeverage: 0,
      totalOtherOperatingDepartments: 0,
      totalGrossOperatingProfitCombined: 0,
      totalGrossOperatingProfit: 0,
      totalEbitda: 0,
      totalEbitdaPercent: 0,
      totalEbit: 0,
      totalEbitPercent: 0,
      totalCapex: 0,
      totalGfa: 0,
      totalCapexPerKey: 0,
      totalIrr: 0,
    };
    let allIRR = [];
    data.forEach((item) => {
      if (!item.yearsTotal) return;

      let irrValues = setIRRTotal(item.calculatedValues);
      allIRR.push(irrValues);
      totals.totalRoomsRevenueCombined += parseValue(
        item.yearsTotal.roomsRevenueCombined
      );
      totals.totalOccupiedRooms += parseValue(item.yearsTotal.occupiedRooms);
      totals.totalTotalRevenueCombined += parseValue(
        item.yearsTotal.totalOperatingRevenue
      );
      totals.totalRoomAvailable += parseValue(
        item.yearsTotal.roomsAvailableCombined
      );
      totals.totalFoodAndBeverageRevenueCombined += parseValue(
        item.yearsTotal.foodAndBeverageRevenueCombined
      );
      totals.totalOtherOperatingDepartmentsRevenueCombined += parseValue(
        item.yearsTotal.otherOperatingDepartmentsRevenueCombined
      );
      totals.totalGrossOperatingProfitCombined += parseValue(
        item.yearsTotal.grossOperatingProfitCombined
      );
      totals.totalEbitda += parseValue(item.yearsTotal.ebitda);
      totals.totalEbit += parseValue(item.yearsTotal.ebit);
      totals.totalCapex += parseValue(item.capex);
      totals.totalGfa += parseValue(item.gfa);
      totals.totalCapexPerKey += parseValue(item.capexPerKey);
      // totals.irr = irr(item.unleveredCashflow);
    });

    if(allIRR.length == 0) 
      totals.irr =0;
    else
       totals.irr = formatPercentage(calculteTotalIRR(allIRR) + "");

    // Calculate ADR (Average Daily Rate) and RevPAR (Revenue Per Available Room)
    totals.totalAdrSar =
      totals.totalRoomsRevenueCombined / totals.totalOccupiedRooms || 0;
    totals.totalAdrUsd = totals.totalAdrSar / 3.75; // Convert SAR to USD
    totals.totalRevParSar =
      totals.totalAdrSar *
      (totals.totalOccupiedRooms / totals.totalRoomAvailable || 0);
    // Assign calculated totals to the item's yearsTotal property
    setTotalOfTotals({
      adrSAR: formatNumber(totals.totalAdrSar),
      adrUSD: formatNumber(totals.totalAdrUsd),
      capex: formatNumber(totals.totalCapex),
      gfa: formatNumber(totals.totalGfa),
      capexPerKey: formatNumber(totals.totalCapexPerKey),
      irr: totals.irr,
      roomsRevenueCombined: formatNumber(totals.totalRoomsRevenueCombined),
      occupiedRooms: formatNumber(totals.totalOccupiedRooms),
      totalOperatingRevenue: formatNumber(totals.totalTotalRevenueCombined),
      occupancyRate: formatPercentage(
        totals.totalOccupiedRooms / totals.totalRoomAvailable || 0
      ),
      roomsAvailableCombined: formatNumber(totals.totalRoomAvailable),
      revPAR: formatNumber(totals.totalRevParSar),
      foodAndBeverageRevenueCombined: formatNumber(
        totals.totalFoodAndBeverageRevenueCombined
      ),
      otherOperatingDepartmentsRevenueCombined: formatNumber(
        totals.totalOtherOperatingDepartmentsRevenueCombined
      ),
      foodAndBeveragePOR: formatNumber(
        totals.totalFoodAndBeverageRevenueCombined /
          totals.totalOccupiedRooms || 0
      ),
      otherOperatingDepartmentsPOR: formatNumber(
        totals.totalOtherOperatingDepartmentsRevenueCombined /
          totals.totalOccupiedRooms || 0
      ),
      roomsRevenue: formatPercentage(
        totals.totalRoomsRevenueCombined / totals.totalTotalRevenueCombined || 0
      ),
      foodAndBeverageRevenue: formatPercentage(
        totals.totalFoodAndBeverageRevenueCombined /
          totals.totalTotalRevenueCombined || 0
      ),

      otherOperatingDepartmentsRevenue: formatPercentage(
        totals.totalOtherOperatingDepartmentsRevenueCombined /
          totals.totalTotalRevenueCombined || 0
      ),
      grossOperatingProfitCombined: formatNumber(
        totals.totalGrossOperatingProfitCombined
      ),
      grossOperatingProfit: formatPercentage(
        totals.totalGrossOperatingProfitCombined /
          totals.totalTotalRevenueCombined || 0
      ),
      ebitda: formatNumber(totals.totalEbitda),
      ebitdaPercent: formatPercentage(
        totals.totalEbitda / totals.totalTotalRevenueCombined || 0
      ),
      ebit: formatNumber(totals.totalEbit),
      ebitPercent: formatPercentage(
        totals.totalEbit / totals.totalTotalRevenueCombined || 0
      ),
    });
  };

  // calculate vertical total
  const allAssetTotal = (data, selectedYears) => {
    let totals = {};
    let yearTotal = {};
    let totalKeysExcl = 0;
    let allTotalKeys = 0;
    selectedYears.forEach((year) => {
      // Initialize totals for the year if it doesn't already exist
      if (!totals[year.value]) {
        totals[year.value] = {
          totalAdrSar: 0,
          totalAdrUsd: 0,
          totalRoomsRevenueCombined: 0,
          totalOccupiedRooms: 0,
          totalTotalRevenueCombined: 0,
          totalOccupancyRate: 0,
          totalRoomAvailable: 0,
          totalRevParSar: 0,
          totalFoodAndBeverageRevenueCombined: 0,
          totalOtherOperatingDepartmentsRevenueCombined: 0,
          totalFoodAndBeveragePOR: 0,
          totalOtherOperatingDepartmentsPOR: 0,
          totalRoomsRevenue: 0,
          totalFoodAndBeverage: 0,
          totalOtherOperatingDepartments: 0,
          totalGrossOperatingProfitCombined: 0,
          totalGrossOperatingProfit: 0,
          totalEbitda: 0,
          totalEbitdaPercent: 0,
          totalEbit: 0,
          totalEbitPercent: 0,
          totalCapex: 0,
          totalGfa: 0,
          totalCapexPerKey: 0,
          totalIrr: 0,
        };
      }

      // Loop through each data item and accumulate totals for the selected year
      data.forEach((item) => {
        allTotalKeys += item.numberOfKeys;
        if (!item.newData) return;
        totalKeysExcl += item.numberOfKeys;
        const yearValue =
          yearType == "long" ? year.value : item.year + parseInt(year.value);
        totals[year.value].totalRoomsRevenueCombined += parseValue(
          item.newData.roomsRevenueCombined[yearValue]
        );
        totals[year.value].totalOccupiedRooms += parseValue(
          item.newData.occupiedRooms[yearValue]
        );
        totals[year.value].totalTotalRevenueCombined += parseValue(
          item.newData.totalOperatingRevenue[yearValue]
        );
        totals[year.value].totalRoomAvailable += parseValue(
          item.newData.roomsAvailableCombined[yearValue]
        );
        totals[year.value].totalFoodAndBeverageRevenueCombined += parseValue(
          item.newData.foodAndBeverageRevenueCombined[yearValue]
        );
        totals[year.value].totalOtherOperatingDepartmentsRevenueCombined +=
          parseValue(
            item.newData.otherOperatingDepartmentsRevenueCombined[yearValue]
          );
        totals[year.value].totalGrossOperatingProfitCombined += parseValue(
          item.newData.grossOperatingProfitCombined[yearValue]
        );
        totals[year.value].totalEbitda += parseValue(
          item.newData.ebitda[yearValue]
        );
        totals[year.value].totalEbit += parseValue(
          item.newData.ebit[yearValue]
        );
      });

      setTotalKeys((prevTotals) => ({
        ...prevTotals,
        totalKeysExcl,
        allTotalKeys,
      }));
      yearTotal[year.value] = {
        adrSAR: formatNumber(
          totals[year.value].totalRoomsRevenueCombined /
            totals[year.value].totalOccupiedRooms || 0
        ),
        adrUSD: formatNumber(
          (totals[year.value].totalRoomsRevenueCombined /
            totals[year.value].totalOccupiedRooms || 0) / 3.75
        ),
        revPAR: formatNumber(
          (totals[year.value].totalRoomsRevenueCombined /
            totals[year.value].totalOccupiedRooms) *
            (totals[year.value].totalOccupiedRooms /
              totals[year.value].totalRoomAvailable || 0)
        ),
        roomsRevenueCombined: formatNumber(
          totals[year.value].totalRoomsRevenueCombined
        ),
        occupiedRooms: formatNumber(totals[year.value].totalOccupiedRooms),
        totalOperatingRevenue: formatNumber(
          totals[year.value].totalTotalRevenueCombined
        ),
        occupancyRate: formatPercentage(
          totals[year.value].totalOccupiedRooms /
            totals[year.value].totalRoomAvailable || 0
        ),
        roomsAvailableCombined: formatNumber(
          totals[year.value].totalRoomAvailable
        ),
        foodAndBeverageRevenueCombined: formatNumber(
          totals[year.value].totalFoodAndBeverageRevenueCombined
        ),
        otherOperatingDepartmentsRevenueCombined: formatNumber(
          totals[year.value].totalOtherOperatingDepartmentsRevenueCombined
        ),
        foodAndBeveragePOR: formatNumber(
          totals[year.value].totalFoodAndBeverageRevenueCombined /
            totals[year.value].totalOccupiedRooms || 0
        ),
        otherOperatingDepartmentsPOR: formatNumber(
          totals[year.value].totalOtherOperatingDepartmentsRevenueCombined /
            totals[year.value].totalOccupiedRooms || 0
        ),
        roomsRevenue: formatPercentage(
          totals[year.value].totalRoomsRevenueCombined /
            totals[year.value].totalTotalRevenueCombined || 0
        ),
        foodAndBeverageRevenue: formatPercentage(
          totals[year.value].totalFoodAndBeverageRevenueCombined /
            totals[year.value].totalTotalRevenueCombined || 0
        ),
        otherOperatingDepartmentsRevenue: formatPercentage(
          totals[year.value].totalOtherOperatingDepartmentsRevenueCombined /
            totals[year.value].totalTotalRevenueCombined || 0
        ),
        grossOperatingProfitCombined: formatNumber(
          totals[year.value].totalGrossOperatingProfitCombined
        ),
        grossOperatingProfit: formatPercentage(
          totals[year.value].totalGrossOperatingProfitCombined /
            totals[year.value].totalTotalRevenueCombined || 0
        ),
        ebitda: formatNumber(totals[year.value].totalEbitda),
        ebitdaPercent: formatPercentage(
          totals[year.value].totalEbitda /
            totals[year.value].totalTotalRevenueCombined || 0
        ),
        ebit: formatNumber(totals[year.value].totalEbit),
        ebitPercent: formatPercentage(
          totals[year.value].totalEbit /
            totals[year.value].totalTotalRevenueCombined || 0
        ),
      };

      // Set or update totals for the selected year
    });
    setTotals(yearTotal);
  };

  // Helper function to parse string values
  const parseValue = (value) =>
    value === "-" ? 0 : parseFloat(value.replace(/,/g, ""));

  const calculateTotalsForSelectedYears = (data, selectedYears) => {
    data.forEach((item) => {
      if (!item.newData) return;
      let totals = {
        totalAdrSar: 0,
        totalAdrUsd: 0,
        totalRoomsRevenueCombined: 0,
        totalOccupiedRooms: 0,
        totalTotalRevenueCombined: 0,
        totalOccupancyRate: 0,
        totalRoomAvailable: 0,
        totalRevParSar: 0,
        totalFoodAndBeverageRevenueCombined: 0,
        totalOtherOperatingDepartmentsRevenueCombined: 0,
        totalFoodAndBeveragePOR: 0,
        totalOtherOperatingDepartmentsPOR: 0,
        totalRoomsRevenue: 0,
        totalFoodAndBeverage: 0,
        totalOtherOperatingDepartments: 0,
        totalGrossOperatingProfitCombined: 0,
        totalGrossOperatingProfit: 0,
        totalEbitda: 0,
        totalEbitdaPercent: 0,
        totalEbit: 0,
        totalEbitPercent: 0,
      };
      selectedYears.forEach((year) => {
        const yearValue =
          yearType == "long" ? year.value : item.year + parseInt(year.value);

        // Safely parse and sum values for the selected year
        totals.totalRoomsRevenueCombined += parseValue(
          item.newData.roomsRevenueCombined[yearValue]
        );
        totals.totalOccupiedRooms += parseValue(
          item.newData.occupiedRooms[yearValue]
        );
        totals.totalTotalRevenueCombined += parseValue(
          item.newData.totalOperatingRevenue[yearValue]
        );
        totals.totalRoomAvailable += parseValue(
          item.newData.roomsAvailableCombined[yearValue]
        );
        totals.totalFoodAndBeverageRevenueCombined += parseValue(
          item.newData.foodAndBeverageRevenueCombined[yearValue]
        );
        totals.totalOtherOperatingDepartmentsRevenueCombined += parseValue(
          item.newData.otherOperatingDepartmentsRevenueCombined[yearValue]
        );
        totals.totalGrossOperatingProfitCombined += parseValue(
          item.newData.grossOperatingProfitCombined[yearValue]
        );
        totals.totalEbitda += parseValue(item.newData.ebitda[yearValue]);
        totals.totalEbit += parseValue(item.newData.ebit[yearValue]);
      });

      // Calculate ADR (Average Daily Rate) and RevPAR (Revenue Per Available Room)
      totals.totalAdrSar =
        totals.totalRoomsRevenueCombined / totals.totalOccupiedRooms || 0;
      totals.totalAdrUsd = totals.totalAdrSar / 3.75; // Convert SAR to USD
      totals.totalRevParSar =
        totals.totalAdrSar *
        (totals.totalOccupiedRooms / totals.totalRoomAvailable || 0);
      // Assign calculated totals to the item's yearsTotal property
      item.yearsTotal = {
        adrSAR: formatNumber(totals.totalAdrSar),
        adrUSD: formatNumber(totals.totalAdrUsd),
        roomsRevenueCombined: formatNumber(totals.totalRoomsRevenueCombined),
        occupiedRooms: formatNumber(totals.totalOccupiedRooms),
        totalOperatingRevenue: formatNumber(totals.totalTotalRevenueCombined),
        occupancyRate: formatPercentage(
          totals.totalOccupiedRooms / totals.totalRoomAvailable || 0
        ),
        roomsAvailableCombined: formatNumber(totals.totalRoomAvailable),
        revPAR: formatNumber(totals.totalRevParSar),
        foodAndBeverageRevenueCombined: formatNumber(
          totals.totalFoodAndBeverageRevenueCombined
        ),
        otherOperatingDepartmentsRevenueCombined: formatNumber(
          totals.totalOtherOperatingDepartmentsRevenueCombined
        ),
        foodAndBeveragePOR: formatNumber(
          totals.totalFoodAndBeverageRevenueCombined /
            totals.totalOccupiedRooms || 0
        ),
        otherOperatingDepartmentsPOR: formatNumber(
          totals.totalOtherOperatingDepartmentsRevenueCombined /
            totals.totalOccupiedRooms || 0
        ),
        roomsRevenue: formatPercentage(
          totals.totalRoomsRevenueCombined / totals.totalTotalRevenueCombined ||
            0
        ),
        foodAndBeverageRevenue: formatPercentage(
          totals.totalFoodAndBeverageRevenueCombined /
            totals.totalTotalRevenueCombined || 0
        ),
        otherOperatingDepartmentsRevenue: formatPercentage(
          totals.totalOtherOperatingDepartmentsRevenueCombined /
            totals.totalTotalRevenueCombined || 0
        ),
        grossOperatingProfitCombined: formatNumber(
          totals.totalGrossOperatingProfitCombined
        ),
        grossOperatingProfit: formatPercentage(
          totals.totalGrossOperatingProfitCombined /
            totals.totalTotalRevenueCombined || 0
        ),
        ebitda: formatNumber(totals.totalEbitda),
        ebitdaPercent: formatPercentage(
          totals.totalEbitda / totals.totalTotalRevenueCombined || 0
        ),
        ebit: formatNumber(totals.totalEbit),
        ebitPercent: formatPercentage(
          totals.totalEbit / totals.totalTotalRevenueCombined || 0
        ),
      };
    });
  };

  return (
    <>
      <MainButton
        title="Back"
        btnStyle="transparent"
        fontColor="dark"
        marginRight="1"
        onPress={() => back(false)}
        icon={leftArrow3}
      />
      <Flex gap="24px" top="73px" width="100%" p={4}>
        <Select
          isMulti
          closeMenuOnSelect={false}
          isClearable
          value={filters.name}
          placeholder=" Asset ..."
          options={renderDropDownItems(nameList)}
          styles={{
            control: (provided) => ({ ...provided, width: 320 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(selectedOption) => {
            handleFilterChange(selectedOption, "name");
          }}
        />
        <Select
          zIndex={1000}
          isMulti
          closeMenuOnSelect={false}
          isClearable
          value={filters.region}
          placeholder="Regions ..."
          options={renderDropDownItems(regionList)}
          styles={{
            control: (provided) => ({ ...provided, width: 320 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "region")
          }
        />
        <Select
          isMulti
          closeMenuOnSelect={false}
          isClearable
          value={filters.position}
          placeholder="Positioning ..."
          options={renderDropDownItems(positionList)}
          styles={{
            control: (provided) => ({ ...provided, width: 320 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "position")
          }
        />
        <Select
          isMulti
          isClearable
          value={filters.operator}
          placeholder="Operator ..."
          options={renderDropDownItems(operatorList)}
          styles={{
            control: (provided) => ({ ...provided, width: 320 }),
            menu: (provided) => ({ ...provided, zIndex: 2 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "operator")
          }
        />
      </Flex>

      <Flex gap="24px" top="73px" width="100%" p={4}>
        <Select
          isMulti
          closeMenuOnSelect={false}
          isClearable
          value={filters.brand}
          placeholder="Brand ..."
          options={renderDropDownItems(brandList)}
          styles={{
            control: (provided) => ({ ...provided, width: 320 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(selectedOption) =>
            handleFilterChange(selectedOption, "brand")
          }
        />
        {yearType == "long" ? (
          <Select
            isMulti
            closeMenuOnSelect={false}
            isClearable
            value={selectedYears}
            placeholder="Select Year ..."
            options={yearOptions}
            styles={{
              control: (provided) => ({ ...provided, width: 320 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(selectedOption) => {
              const sortedYears = (selectedOption || []).sort(
                (a, b) => a.value - b.value
              );
              setSelectedYears(sortedYears);
            }}
          />
        ) : (
          <Select
            isMulti
            closeMenuOnSelect={false}
            isClearable
            value={selectedYears}
            placeholder="Select Year ..."
            options={yearOptionsShort}
            styles={{
              control: (provided) => ({ ...provided, width: 320 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            onChange={(selectedOption) => {
              const sortedYears = (selectedOption || []).sort(
                (a, b) => a.value - b.value
              );
              setSelectedYears(sortedYears);
            }}
          />
        )}
        <Select
          isMulti
          closeMenuOnSelect={false}
          isClearable
          value={selectedColumns}
          placeholder="Select Columns ..."
          options={columnOptions}
          styles={{
            control: (provided) => ({ ...provided, width: 320 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onChange={(selectedOption) => {
            const sortedColumns = (selectedOption || []).sort(
              (a, b) => keyColumnsMap[a.value] - keyColumnsMap[b.value]
            );
            setSelectedColumns(sortedColumns);
          }}
        />
      </Flex>
      <Flex gap="24px" top="73px" width="100%" p={4}>
        <RadioGroup
          onChange={setViewType}
          value={viewtype}
          defaultValue="individual"
        >
          <Stack spacing={5} direction="row">
            <Radio colorScheme="red" value="aggregated">
              Aggregated
            </Radio>
            <Radio colorScheme="green" value="individual">
              Individual
            </Radio>
          </Stack>
        </RadioGroup>

        <RadioGroup
          onChange={(value) => {
            setCalculationDone(false);
            setYearType(value);
            setSelectedYears(
              value == "short"
                ? [{ label: "Year 5", value: "4" }]
                : [{ label: "2030", value: "2030" }]
            );
          }}
          value={yearType}
          defaultValue="long"
        >
          <Stack spacing={5} direction="row">
            <Radio colorScheme="red" value="short">
              Project Year
            </Radio>
            <Radio colorScheme="green" value="long">
              Calendar Year
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      <Box mt="24px" display="flex" justifyContent="space-between">
        <MainTitle title="KPI Summary" />
        <Tooltip label="Export to Excel">
          <IconButton
            borderRadius="5px"
            icon={<Image src={downloadIcon} />}
            onClick={
              viewtype == "individual"
                ? exportToExcel
                : exportAggregationToExcel
            }
          />
        </Tooltip>
      </Box>

      <MainBox>
        {loading ? (
          <Box width="100%" paddingY={10}>
            <Center>
              <Spinner />
            </Center>
          </Box>
        ) : calculationDone && viewtype === "individual" ? (
          <CustomTable
            hotelInfo={filteredData}
            selectedYears={selectedYears}
            selectedColumns={selectedColumns}
            totalKeys={totalKeys}
            totals={totals} // Pass the totals to the CustomTable component
            totalOfTotals={totalOfTotals}
            yearType={yearType}
            viewtype={viewtype}
          />
        ) : calculationDone ? (
          <AggregationKPI
            selectedYears={selectedYears}
            selectedColumns={selectedColumns}
            totals={totals} // Pass the totals to the CustomTable component
            totalOfTotals={totalOfTotals}
          />
        ) : (
          <></>
        )}
      </MainBox>
    </>
  );
};

export default KpiTemplate;
