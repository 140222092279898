import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const AggregationKPI = ({
  selectedYears,
  selectedColumns,
  totals, // Assuming totals are passed as props
  totalOfTotals,
}) => {
  const borderStyle = "2px solid gray"; // Adjust the color and thickness as needed
  const yearslength = selectedYears.length > 0;
  const renderYearHeaders = (selectedYears, prefix, borderStyle) =>
    selectedYears.map((year, index) => (
      <Th key={`${prefix}-${index}`} borderRight={borderStyle}>
        {year.label}
      </Th>
    ));

  return (
    <TableContainer>
      <Table variant="simple" size="md" border="1px solid #E2E8F0">
        <Thead>
          <Tr>
            <Th borderRight={borderStyle}>KPI</Th>
            {selectedColumns.length > 0 && yearslength && (
              
                <>
                  {renderYearHeaders(selectedYears, "col", null)}
                  <Th borderRight={borderStyle}>Total</Th>
                </>
              
            )}
          </Tr>
        </Thead>
        <Tbody fontSize="sm">
          {selectedColumns.map((col, index) => (
            <Tr  key={index}>
              <Td key={`${index}-col.value`} borderRight={borderStyle}>{col.label}</Td>
              {yearslength &&
                Object.keys(totals).length == selectedYears.length && (
                  <>
                    {selectedYears.map(
                      (year, index) => (
                        (
                          <Td>
                            {totals[year.value][col.value]
                              ? totals[year.value][col.value]
                              : "-"}
                          </Td>
                        )
                      )
                    )}
                    <Td borderRight={borderStyle}>
                      {totalOfTotals[col.value]||"-"}
                    </Td>
                  </>
                )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AggregationKPI;
